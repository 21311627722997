import { defineStore } from 'pinia'

export const useHomeSlideStore = defineStore('homeSlide', {
  state: () => ({
    active: 1
  }),
  getters: {
    getActive: (state) => parseInt(state.active)
  },
  actions: {
    setActive(value) {
      this.active = value
    }
  }
})
