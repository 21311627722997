<script setup>
const emit = defineEmits(['onGoToContact'])
defineProps({
  activeComponent: String
})

const goToContact = () => {
  emit('onGoToContact', 'homeContact')
}
</script>
<template>
  <section class="home--try">
    <div class="home--try-wrapper">
      <div class="home--try-title">
        {{ $t('home_try.title') }}
      </div>
      <div class="home--try-btn">
        <button class="custom-btn-inverted" @click="goToContact">
          {{ $t('home_try.button') }}
        </button>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.home--try {
  height: 440px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url('/assets/home/try/banner.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .home--try-wrapper {
    padding: 0 15px;
    .home--try-title {
      color: #213a50;
      font-size: 41px;
      letter-spacing: -2.05px;
      font-family: 'CircularStd-Black';
    }
    .home--try-btn {
      padding-top: 60px;
      button {
        font-size: 20px;
        background-color: black;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .home--try {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url('/assets/home/try/banner.webp');
    background-position: center;
    background-repeat: no-repeat;
    .home--try-wrapper {
      .home--try-title {
        color: #213a50;
        font-size: 35px;
        letter-spacing: -2.05px;
        font-family: 'CircularStd-Black';
      }
      .home--try-btn {
        padding-top: 30px;
        button {
          font-size: 18px;
          background-color: black;
        }
      }
    }
  }
}
</style>
