<script setup>
import AOS from 'aos'
import 'aos/dist/aos.css'

AOS.init({
  duration: 2000,
  once: true
})
</script>
<template>
  <section class="home--invoice">
    <div class="home--invoice-wrapper container">
      <div class="row custom-row">
        <div class="col-lg-5">
          <div class="home--invoice-wrapper-title">
            {{ $t('home_invoice.block_1_title') }}
            <span class="primary-color">{{ $t('home_invoice.block_1_title_branding') }}</span>
          </div>
          <div class="home--invoice-wrapper-body">
            {{ $t('home_invoice.block_1_text') }}
            <span class="">{{ $t('home_invoice.block_1_text_bold') }}</span>
          </div>
          <div class="home--invoice-wrapper-body">
            {{ $t('home_invoice.block_1_text_1') }}
          </div>
        </div>
        <div class="col-lg-7 custom-img-col">
          <img data-aos="zoom-in" src="/assets/home/invoice/Factura_1.png" />
          <div
            class="home--invoice-icon icons-first"
            data-aos="zoom-out"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="1000"
          >
            <img src="/assets/home/invoice/Grupo_3608.svg" />
          </div>
        </div>
      </div>
      <div class="row custom-row">
        <div class="col-lg-8 col-second-wrapper">
          <img data-aos="zoom-in" src="/assets/home/invoice/Factura_2.svg" />
          <div
            class="home--invoice-icon icons-second"
            data-aos="zoom-out"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="1000"
          >
            <img src="/assets/home/invoice/Grupo_3605.svg" />
          </div>
        </div>
        <div class="col-lg-4">
          <div class="home--invoice-wrapper-title col-second">
            <div class="home--invoice-wrapper-title-content">
              {{ $t('home_invoice.block_2_title') }}
              <div class="primary-color">{{ $t('home_invoice.block_2_title_branding') }}</div>
            </div>
          </div>
          <div class="home--invoice-wrapper-body">
            {{ $t('home_invoice.block_2_text') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.home--invoice {
  background: transparent linear-gradient(0deg, #9cacc5 0%, #f1f1f1 100%) 0% 0% no-repeat
    padding-box;
  .home--invoice-wrapper {
    padding: 140px 70px 110px 70px;
    .home--invoice-wrapper-title {
      font-size: 37px;
      font-family: 'CircularStd-Black';
      line-height: 38px;
      padding-bottom: 30px;
      &.col-second {
        padding-top: 7px;
        padding-bottom: 10px;
        .home--invoice-wrapper-title-content {
          padding-top: 20px;
        }
      }
    }
    .home--invoice-wrapper-body {
      font-size: 16px;
      letter-spacing: -0.8px;
      font-family: 'CircularStd-Bold';
      padding-bottom: 25px;
      span {
        font-family: 'CircularStd-Black';
      }
    }
    .custom-row {
      .home--invoice-icon {
        position: relative;
        &.icons-first {
          img {
            position: absolute;
            bottom: -70px;
            left: -15px;
            min-width: 120px;
          }
        }
        &.icons-second {
          padding-bottom: 55px;
          padding-left: 55px;
          img {
            position: relative;
            min-width: 120px;
          }
        }
      }
      &:last-child {
        padding-top: 180px;
      }
      .col-second-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        img {
          max-width: 100%;
          padding-top: 30px;
        }
        .icons-second {
          img {
            max-width: 100%;
            padding-top: 0;
          }
        }
      }
      .custom-img-col {
        text-align: end;
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .home--invoice {
    background: transparent linear-gradient(0deg, #9cacc5 0%, #f1f1f1 100%) 0% 0% no-repeat
      padding-box;
    .home--invoice-wrapper {
      padding: 100px 20px 80px 20px;
      .home--invoice-wrapper-title {
        font-size: 35px;
        font-family: 'CircularStd-Black';
        line-height: 35px;
        padding-bottom: 30px;
        &.col-second {
          padding-top: 7px;
          padding-bottom: 10px;
          .home--invoice-wrapper-title-content {
            padding-top: 20px;
          }
        }
      }
      .home--invoice-wrapper-body {
        font-size: 15px;
        letter-spacing: -0.8px;
        font-family: 'CircularStd-Bold';
        padding-bottom: 25px;
        span {
          font-family: 'CircularStd-Black';
        }
      }
      .custom-row {
        .home--invoice-icon {
          position: relative;
          &.icons-first {
            img {
              position: absolute;
              bottom: -70px;
              left: -15px;
              max-width: 70px;
            }
          }
          &.icons-second {
            padding: 10px 15px;
            img {
              position: relative;
              max-width: 70px;
            }
          }
        }
        &:last-child {
          padding-top: 80px;
        }
        .col-second-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          img {
            max-width: 100%;
            padding-top: 30px;
          }
          .icons-second {
            img {
              max-width: 70px;
              padding-top: 0;
            }
          }
        }
        .custom-img-col {
          text-align: end;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .home--invoice {
    background: transparent linear-gradient(0deg, #9cacc5 0%, #f1f1f1 100%) 0% 0% no-repeat
      padding-box;
    .home--invoice-wrapper {
      padding: 100px 20px 80px 20px;
      .home--invoice-wrapper-title {
        font-size: 35px;
        font-family: 'CircularStd-Black';
        line-height: 35px;
        padding-bottom: 30px;
        &.col-second {
          padding-top: 7px;
          padding-bottom: 10px;
          .home--invoice-wrapper-title-content {
            padding-top: 20px;
          }
        }
      }
      .home--invoice-wrapper-body {
        font-size: 15px;
        letter-spacing: -0.8px;
        font-family: 'CircularStd-Bold';
        padding-bottom: 25px;
        span {
          font-family: 'CircularStd-Black';
        }
      }
      .custom-row {
        .home--invoice-icon {
          position: relative;
          text-align: center;
          &.icons-first {
            img {
              position: absolute;
              bottom: -70px;
              left: -15px;
              max-width: 70px;
            }
          }
          &.icons-second {
            padding: 10px 15px;
            img {
              position: relative;
              max-width: 70px;
            }
          }
        }
        &:last-child {
          padding-top: 80px;
        }
        .col-second-wrapper {
          display: block;
          justify-content: flex-start;
          align-items: flex-start;
          img {
            max-width: 100%;
            padding-top: 30px;
          }
          .icons-second {
            img {
              max-width: 70px;
              padding-top: 0;
            }
          }
        }
        .custom-img-col {
          text-align: end;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
