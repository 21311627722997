<script setup>
import { ref, watch } from 'vue'
import HomeHeader from '@/components/home/HomeHeader.vue'
import HomeIntro from '@/components/home/HomeIntro.vue'
import HomeDigitalize from '@/components/home/HomeDigitalize.vue'
import HomeJourney from '@/components/home/HomeJourney.vue'
import HomeInvoice from '@/components/home/HomeInvoice.vue'
import HomeTry from '@/components/home/HomeTry.vue'
import HomeBlocks from '@/components/home/HomeBlocks.vue'
import HomeAdvantages from '@/components/home/HomeAdvantages.vue'
import HomeConnect from '@/components/home/HomeConnect.vue'
import HomeForm from '@/components/home/HomeForm.vue'
import HomeReparation from '@/components/home/HomeReparation.vue'

import AOS from 'aos'
import 'aos/dist/aos.css'

const emit = defineEmits(['onChangeActive'])
const props = defineProps({
  activeComponent: String
})

AOS.init({
  duration: 2000,
  once: true
})

const homeIntro = ref()
const homeDigitalize = ref()
const homeJourney = ref()
const homeInvoice = ref()
const homeTry = ref()
const homeBlocks = ref()
const homeAdvantages = ref()
const homeConnect = ref()
const homeContact = ref()
const homeReparation = ref()

watch(
  () => props.activeComponent,
  (selection) => {
    console.log("will smooth")
    eval(selection).value?.scrollIntoView({ behavior: 'smooth' })
  }
)

function onGoToContact(name) {
  emit('onChangeActive', name)
}
</script>

<template>
  <div class="home-container">
    <HomeHeader :activeComponent="activeComponent" @onGoToContact="onGoToContact" />
    <div class="w-100" id="home-intro" ref="homeIntro">
      <HomeIntro />
    </div>
    <div class="w-100" id="home-digitalize" ref="homeDigitalize">
      <HomeDigitalize :activeComponent="activeComponent" @onGoToContact="onGoToContact" />
    </div>
    <div class="w-100" id="home-try" ref="homeReparation">
      <HomeReparation />
    </div>
    <div class="w-100" data-aos="fade-up" id="home-journey" ref="homeJourney">
      <HomeJourney />
    </div>
    <div class="w-100" data-aos="fade-up" id="home-invoice" ref="homeInvoice">
      <HomeInvoice />
    </div>
    <div class="w-100" id="home-try" ref="homeTry">
      <HomeTry :activeComponent="activeComponent" @onGoToContact="onGoToContact" />
    </div>
    <div class="w-100" data-aos="fade-up" id="home-blocks" ref="homeBlocks">
      <HomeBlocks />
    </div>
    <div class="w-100" id="home-advantages">
      <div class="" ref="homeAdvantages">
        <HomeAdvantages />
      </div>
    </div>
    <div class="w-100" id="home-connect" ref="homeConnect">
      <HomeConnect />
    </div>
    <div class="w-100" id="home-contact" ref="homeContact">
      <HomeForm />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.home-container {
  padding-top: 70px;
  width: 100%;
  min-height: 710px;
}
</style>
