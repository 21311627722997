<script setup>
    import { ref } from 'vue'
    let videoUrl = ref(new URL(`/public/assets/Car_Factura_Comercial_LOW.webm`, import.meta.url).href)
</script>
<template>
    <section class="w-100 custom-container-reparation">
        <div class="custom-wrapper home-reparation">
            <video
                class="home-powerful-video-js"
                controls
                preload="none"
                width="1100"
                height="550"
                controlsList="nodownload"
                aspectRatio="9:16"
                poster="/assets/Captura_CAR_FACTURA0.png"
                data-setup="{}">
                <source :src=videoUrl type="video/webm">
            </video>
        </div>
    </section>
</template>
<style lang="scss" scoped>
.custom-container-reparation {
    display: flex;
    justify-content: center;
    background-color: black;
}

@media screen and (max-width: 800px) {
    .custom-container-reparation video {
        width: 100%;
        height: 100%;
    }
}
</style>