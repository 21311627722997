import { createRouter, createWebHistory } from 'vue-router'
import { getPageTitle } from '../utils/PageTitle.js'
import HomeView from '../views/HomeView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
        title: 'home'
      }
    },
    {
      path: '/legal',
      name: 'legal',
      component: () => import('@/views/LegalAdviceView.vue'),
      meta: {
        title: 'legal'
      }
    },
    {
      path: '/privacy-security',
      name: 'privacySecurity',
      component: () => import('@/views/PrivacySecurityView.vue'),
      meta: {
        title: 'privacySecurity'
      }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('@/views/PoliticaPrivacidadView.vue'),
      meta: {
        title: 'privacy'
      }
    },
    {
      path: '/cookies',
      name: 'cookies',
      component: () => import('@/views/CookiesView.vue'),
      meta: {
        title: 'cookies'
      }
    },
    {
      path: '/digital_kit',
      name: 'digital_kit',
      component: () => import("@/views/DigitalKitView.vue"),
      meta: { 
        title: "digital_kit"
      }
    },
    {
      path: '/intro',
      name: 'intro',
      component: () => import("@/views/IntroView.vue"),
      meta: { 
        title: "intro"
      }
    },
    {
      path: "/:pathMatch(.*)*",
      name: "BadPage",
      component: () => import("@/views/BadPageView.vue"),
      meta: { 
        title: "bad_page"
      }
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    return { top: 0 }
  }
})

router.beforeEach((to, from) => {
  window.document.title = getPageTitle(to.meta.title)
})

export default router
