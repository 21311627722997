<script setup>
import AOS from 'aos'
import HeaderBanner from '@/components/home/HeaderBanner.vue'
import 'aos/dist/aos.css'

const emit = defineEmits(['onGoToContact'])
defineProps({
  activeComponent: String
})

const goToContact = () => {
  emit('onGoToContact', 'homeContact')
}

AOS.init({
  duration: 2000,
  once: true
})
</script>
<template>
  <div class="home--header container">
    <HeaderBanner @onGoToContact="goToContact" />
    <div class="home--header-wrapper">
      <div class="home--header-title">
        {{ $t('home_header.title') }}
        <div class="primary-color">{{ $t('home_header.title_branding') }}</div>
      </div>
      <div class="home--header-subtitle">
        {{ $t('home_header.subtitle') }}
      </div>
      <div class="home--header-body">
        <div class="home--header-imgs">
          <div
            class="home--header-img-left"
            data-aos="zoom-out"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="2000"
          >
            <img src="/assets/home/APK_ombra2.png" alt="apk" />
          </div>
          <div
            class="home--header-img-center"
            data-aos="zoom-out"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="1000"
          >
            <img src="/assets/home/computer.png" alt="computer" />
          </div>
          <div
            class="home--header-img-right"
            data-aos="zoom-out"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="3000"
          >
            <img src="/assets/home/mock_up_doble_big.png" alt="mobile" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.home--header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 125px;
  padding-bottom: 50px;
  .home--header-wrapper {
    text-align: center;
    div {
      width: 100%;
    }
    .home--header-title {
      font-size: 55px;
      letter-spacing: -2.75px;
      line-height: 63px;
      font-family: 'CircularStd-Black';
    }
    .home--header-subtitle {
      font-size: 22px;
      letter-spacing: -1.1px;
      padding-top: 32px;
    }
    .home--header-body {
      display: flex;
      align-items: center;
      justify-content: center;
      .home--header-imgs {
        display: flex;
        position: relative;
        padding-top: 15px;
        .home--header-img-left {
          position: absolute;
          left: -300px;
          top: 145px;
          z-index: 1;
        }
        .home--header-img-center {
          z-index: 2;
        }
        .home--header-img-right {
          position: absolute;
          right: -350px;
          bottom: 31px;
          z-index: 2;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .home--header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 50px;
    .home--header-wrapper {
      text-align: center;
      div {
        width: 100%;
      }
      .home--header-title {
        font-size: 45px;
        letter-spacing: -2.75px;
        line-height: 50px;
        font-family: 'CircularStd-Black';
      }
      .home--header-subtitle {
        font-size: 20px;
        letter-spacing: -1.1px;
        padding-top: 20px;
      }
      .home--header-body {
        display: flex;
        align-items: center;
        justify-content: center;
        .home--header-imgs {
          display: flex;
          position: relative;
          padding-top: 15px;
          .home--header-img-left {
            max-width: 195px;
            position: absolute;
            left: -35px;
            top: 134px;
            z-index: 1;
            img {
              width: 100%;
            }
          }
          .home--header-img-center {
            max-width: 450px;
            z-index: 2;
            img {
              width: 100%;
            }
          }
          .home--header-img-right {
            max-width: 200px;
            position: absolute;
            right: -44px;
            bottom: 10px;
            z-index: 2;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .home--header-wrapper {
    padding-top: 30px;
  }
  .home--header .home--header-wrapper .home--header-body .home--header-imgs {
    justify-content: center;
  }
  .home--header .home--header-wrapper .home--header-body .home--header-imgs .home--header-img-center {
    max-width: 280px;
    left: 50px;
  }
  .home--header .home--header-wrapper .home--header-body .home--header-imgs .home--header-img-left {
    max-width: 150px;
    left: -2px;
    top: 100px;
    padding-left: 20px;
  }
  .home--header .home--header-wrapper .home--header-body .home--header-imgs .home--header-img-right {
    max-width: 115px;
    right: 5px
  }
  .home--header .home--header-wrapper .home--header-body .home--header-imgs .home--header-img-right img {
    max-width: 115px;
  }
}
</style>
