<template>
  <section class="home_cruise">
    <div class="w-100">
      <div class="inner-wrapper-journey-desktop">
        <div class="cruise-lines cruise-lines-journey container-fluid">
          <div class="cruise-inner">
            <img src="/assets/home/cruise/journey.svg" />
          </div>
        </div>
        <div class="cruise-lines inner-cruise container-fluid">
          <div class="wrapper-line">
            <div class="cruise-line">
              <div class="liner upper-line">
                <div
                  class="upper-image"
                  data-aos="zoom-out"
                  data-aos-easing="ease-in-back"
                  data-aos-duration="700"
                >
                  <img src="/assets/home/cruise/cruise_1.png" loading="lazy" />
                </div>
                <div
                  class="upper-text"
                  data-aos="fade"
                  data-aos-easing="ease-in-back"
                  data-aos-duration="700"
                >
                  <div class="primary-color title-number">01.</div>
                  <div class="title">
                    <span>
                      {{ $t('home_journey.tag_1_bold') }}
                    </span>
                    {{ $t('home_journey.tag_1') }}
                  </div>
                </div>
              </div>
              <div class="liner down-line custom-top-padding-double">
                <div
                  class="upper-text"
                  data-aos="fade"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="400"
                  data-aos-duration="700"
                >
                  <div class="primary-color title-number">02.</div>
                  <div class="title">
                    <span>
                      {{ $t('home_journey.tag_2_bold') }}
                    </span>
                    {{ $t('home_journey.tag_2') }}
                  </div>
                </div>
                <div
                  class="upper-image"
                  data-aos="zoom-out"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="300"
                  data-aos-duration="700"
                >
                  <img src="/assets/home/cruise/cruise_2.png" loading="lazy" />
                </div>
              </div>
              <div class="liner upper-line">
                <div
                  class="upper-image"
                  data-aos="zoom-out"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="800"
                  data-aos-duration="700"
                >
                  <img src="/assets/home/cruise/cruise_3.jpg" loading="lazy" />
                </div>
                <div
                  class="upper-text"
                  data-aos="fade"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="700"
                  data-aos-duration="700"
                >
                  <div class="primary-color title-number">03.</div>
                  <div class="title">
                    <span>
                      {{ $t('home_journey.tag_3_bold') }}
                    </span>
                    {{ $t('home_journey.tag_3') }}
                  </div>
                </div>
              </div>
              <div class="liner down-line custom-top-padding">
                <div
                  class="upper-text"
                  data-aos="fade"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="1100"
                  data-aos-duration="700"
                >
                  <div class="primary-color title-number">04.</div>
                  <div class="title">
                    <span>
                      {{ $t('home_journey.tag_4_bold') }}
                    </span>
                    {{ $t('home_journey.tag_4') }}
                  </div>
                </div>
                <div
                  class="upper-image"
                  data-aos="zoom-out"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="1200"
                  data-aos-duration="700"
                >
                  <img src="/assets/home/cruise/cruise_4.png" loading="lazy" />
                </div>
              </div>
              <div class="liner upper-line cruise-fifth-block">
                <div
                  class="upper-image"
                  data-aos="zoom-out"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="1600"
                  data-aos-duration="700"
                >
                  <img src="/assets/home/cruise/cruise_5.png" loading="lazy" />
                </div>
                <div
                  class="upper-text"
                  data-aos="fade"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="1500"
                  data-aos-duration="700"
                >
                  <div class="title-number primary-color">05.</div>
                  <div class="title">
                    <span>
                      {{ $t('home_journey.tag_5_bold') }}
                    </span>
                    {{ $t('home_journey.tag_5') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="inner-wrapper-journey-mobile">
        <div class="home-journey-mobile" data-aos="zoom-in">
          <img src="/assets/home/cruise/journey_mobile.svg" />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.home_cruise {
  padding-top: 0px;
  padding-bottom: 60px;
}
.wrapper {
  text-align: center;
  .title {
    font-size: 40px;
    font-family: 'CircularStd-Black';
    letter-spacing: -2px;
  }
  .subtitle {
    font-size: 16px;
  }
}
.inner-wrapper-journey-desktop {
  padding-top: 40px;
}
.cruise-lines-journey {
  position: relative;
}
.title-number {
  font-size: 56px;
  letter-spacing: -2.8px;
  font-family: 'CircularStd-Bold';
  text-align: left;
}
.cruise-lines {
  max-width: 1250px;
  letter-spacing: -0.95px;
  .cruise-inner {
    width: 1250px;
    position: absolute;
    top: 90px;
    left: 1px;
    z-index: 1;
  }
  .liner {
    flex: 1 1 0;
    img {
      width: 100%;
    }
  }

  .wrapper-line {
    .cruise-line {
      display: flex;
      width: 100%;
      .title {
        text-align: left;
      }
      .upper-line,
      .down-line {
        padding: 4px 30px 0 30px;
        .primary-text {
          font-size: 40px;
          font-family: 'CircularStd-Bold';
        }
        &.cruise-fifth-block {
          padding-right: 40px;
        }
        span {
          font-family: 'CircularStd-Bold';
        }
      }

      .upper-line {
        padding-top: 124px;
      }
      .custom-top-padding {
        padding-top: 80px;
        padding-left: 20px;
        .upper-text {
          padding-bottom: 20px;
        }
      }
      .custom-top-padding-double {
        padding-top: 55px;
        .title {
          padding-bottom: 20px;
        }
      }
    }
  }
}
.inner-wrapper-journey-mobile {
  display: none;
}
@media screen and (max-width: 1450px) {
  .cruise-lines .cruise-inner {
    width: 100%;
    position: absolute;
    top: 114px;
    left: 8px;
    z-index: 1;
  }
}

@media (max-width: 1350px) {
  .inner-wrapper-journey-desktop {
    display: none;
  }
  .inner-wrapper-journey-mobile {
    display: flex;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  .wrapper {
    .title {
      font-size: 2rem;
      padding: 0 45px 50px;
      line-height: 2.1rem;
      span {
        padding: 0;
      }
    }

    .subtitle {
      display: none;
    }
  }
  .home_cruise {
    padding-top: 60px;
    padding-bottom: 80px;
  }
}
</style>
