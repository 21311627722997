<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

let name
let email
let telf
let company
let help
let agreeCheck
let agreeCheck2
let xnQsjsdp = '6c1b28369d668c2c8cecc3b8c05c1b93f438d21da77cbc8f4a035432b5b39616'
let xmIwtLD = 'c92127d3c81dd385ec059caace30d10cdf6fe09907a2178e629f77e64991c56f'
let actionType = 'TGVhZHM='
const error = ref('')
const showError = ref(false)
const sendOkMsg = ref(false)
const showOk = ref(false)

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function checkForm() {
  error.value = ''
  showError.value = false
  showOk.value = false
  sendOkMsg.value = ''
  if (!name) {
    error.value = t('forms.required_name')
    showError.value = true
    setTimeout(() => {
      showError.value = false
    }, 5000)
    return
  }
  if (!email) {
    error.value = t('forms.required_email')
    showError.value = true
    setTimeout(() => {
      showError.value = false
    }, 5000)
    return
  }
  if(!validateEmail(email)) {
    error.value = t('forms.required_email_valid')
    showError.value = true
    setTimeout(() => {
      showError.value = false
    }, 5000)
    return
  }
  if (!company) {
    error.value = t('forms.required_company')
    showError.value = true
    setTimeout(() => {
      showError.value = false
    }, 5000)
    return
  }
  if (!agreeCheck) {
    error.value = t('custom_coookies.accept_policy')
    showError.value = true
    setTimeout(() => {
      showError.value = false
    }, 5000)
    return
  }
  if (!agreeCheck2) {
    error.value = t('custom_coookies.accept_policy')
    showError.value = true
    setTimeout(() => {
      showError.value = false
    }, 5000)
    return
  }

  let formData = new FormData()
  formData.append('Email', email)
  formData.append('Company', company)
  formData.append('Phone', telf)
  formData.append('Mobile', telf)
  formData.append('First Name', name)
  formData.append('Last Name', name)
  formData.append('Lead Source', 'Web CARFACTURA CAR')
  formData.append('Lead Status', 'No contactado')
  formData.append('returnURL', 'https://www.cloudactivereception.com/')
  formData.append('LEADCF8', '-None-')
  formData.append('xnQsjsdp', xnQsjsdp)
  formData.append('xmIwtLD', xmIwtLD)
  formData.append('actionType', actionType)
  formData.append('zc_gad', '')

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    mode: 'no-cors',
    body: formData
  }
  fetch('https://crm.zoho.eu/crm/WebToLeadForm', requestOptions).then(() => {
    sendOkMsg.value = t('forms.ok_message')
    showOk.value = true
    setTimeout(() => {
      showOk.value = false
    }, 5000)
  })
}
</script>
<template>
  <section class="home--form">
    <div class="home--form-wrapper">
      <div class="home--form-titles">
        <div class="home--form-title">
          {{ $t('home_form.title') }}
          <span class="primary-color">
            {{ $t('home_form.title_branding') }}
          </span>
        </div>
        <div class="home--form-subtitle">
          {{ $t('home_form.subtitle') }}
        </div>
      </div>
      <div class="home--form-body">
        <div class="home--form-body-wrapper">
          <div class="home--form-body-inner">
            <div class="home--form-header">
              {{ $t('home_form.header') }}
            </div>
            <div class="home--form-body-block">
              <form @submit.prevent="checkForm">
                <div class="contact-form">
                  <div class="contact-form-block">
                    <input
                      class="contact-input"
                      v-model="name"
                      :placeholder="t('home_form.name')"
                    />
                  </div>
                  <div class="contact-form-block">
                    <input
                      class="contact-input"
                      v-model="email"
                      :placeholder="t('home_form.email')"
                    />
                  </div>
                  <div class="contact-form-block">
                    <input
                      class="contact-input"
                      v-model="telf"
                      :placeholder="t('home_form.telf')"
                    />
                  </div>
                  <div class="contact-form-block">
                    <input
                      class="contact-input"
                      v-model="company"
                      :placeholder="t('home_form.company')"
                    />
                  </div>
                  <div class="contact-form-block">
                    <textarea
                      class="custom-text-area"
                      rows="5"
                      v-model="help"
                      :placeholder="t('home_form.help')"
                    />
                  </div>
                </div>
                <div class="contact-form-extra-wrapper">
                  <div class="contact-form-more">
                    <div class="contact-form-requirements">
                      <div class="contact-form-requirements-title">
                        {{ $t('forms.contact_title') }}
                      </div>
                    </div>
                    <div class="contact-form-requirements">
                      <div class="contact-form-requirements-title">
                        <span class="contact-form-bold">{{ $t('forms.contact_dpd') }}: </span>
                        <a href="mailto:dpd@inforserveis.es">
                          dpd@inforserveis.es
                        </a>
                      </div>
                    </div>
                    <div class="contact-form-requirements">
                      <div class="contact-form-requirements-title">
                        <span class="contact-form-bold">{{ $t('forms.contact_finality') }}:</span>
                        {{ $t('forms.contact_finality_text') }}
                      </div>
                    </div>
                    <div class="contact-form-requirements">
                      <div class="contact-form-requirements-title">
                        <span class="contact-form-bold">{{ $t('forms.contact_legimitation') }}:</span>
                        {{ $t('forms.contact_legimitation_text') }}
                      </div>                    
                    </div>
                    <div class="contact-form-requirements">
                      <div class="contact-form-requirements-title">
                        <span class="contact-form-bold">{{ $t('forms.contact_assignment') }}:</span>
                        {{ $t('forms.contact_assignment_text') }}
                      </div>
                    </div>
                    <div class="contact-form-requirements">
                      <div class="contact-form-requirements-title">
                        <span class="contact-form-bold">{{ $t('forms.contact_rights') }}:</span>
                        {{ $t('forms.contact_rights_texts') }}
                        <a class="mailto" href="mailto:contact@test.com">
                          {{ $t('forms.contact_rights_email') }}
                        </a>
                      </div>
                    </div>
                    <div class="contact-form-requirements">
                      <div class="contact-form-requirements-title">
                        <span class="contact-form-bold">{{ $t('forms.contact_information') }}:</span>
                        {{ $t('forms.contact_information_text') }}
                        <router-link to="/privacy">
                          {{ $t('forms.contact_information_link') }}
                        </router-link>
                      </div>
                    </div>
                    <div class="contact-agreement">
                      <label for="agreeCheck" class="custom-checkboxs">
                        <input
                          class="checkbox__input"
                          type="checkbox"
                          id="agreeCheck"
                          v-model="agreeCheck"
                        />
                        <svg
                          class="checkbox__icon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 22 22"
                        >
                          <rect
                            width="21"
                            height="21"
                            x=".5"
                            y=".5"
                            fill="#FFF"
                            stroke="#000"
                            rx="0"
                          />
                          <path
                            class="tick"
                            stroke="#0047ba"
                            fill="none"
                            stroke-linecap="round"
                            stroke-width="4"
                            d="M4 10l5 5 9-9"
                          />
                        </svg>
                        <span class="checkbox__label">{{ $t('forms.accept_first') }}</span>
                      </label>
                    </div>
                    <div class="contact-agreement">
                      <label for="agreeCheck2" class="custom-checkboxs">
                        <input
                          class="checkbox__input"
                          type="checkbox"
                          id="agreeCheck2"
                          v-model="agreeCheck2"
                        />
                        <svg
                          class="checkbox__icon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 22 22"
                        >
                          <rect
                            width="21"
                            height="21"
                            x=".5"
                            y=".5"
                            fill="#FFF"
                            stroke="#000"
                            rx="0"
                          />
                          <path
                            class="tick"
                            stroke="#0047ba"
                            fill="none"
                            stroke-linecap="round"
                            stroke-width="4"
                            d="M4 10l5 5 9-9"
                          />
                        </svg>
                        <span class="checkbox__label">{{ $t('forms.accept_second') }}.
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="contact-error" v-if="showError">
                    <div class="contact-error-wrapper">
                      {{ error }}
                    </div>
                  </div>
                  <div class="contact-ok" v-if="showOk">
                    <div class="contact-error-wrapper">
                      {{ sendOkMsg }}
                    </div>
                  </div>
                </div>
                <div class="contact-send">
                  <button class="custom-btn-inverted" type="submit">
                    {{ $t('home_form.ask_demo') }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
@import '../../styles/common.scss';
.home--form {
  height: 1530px;
  background-image: url('/assets/home/formulary/form_factura.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .home--form-wrapper {
    padding-top: 150px;
    .home--form-titles {
      text-align: center;
      padding: 0 15px;
      .home--form-title {
        font-size: 40px;
        letter-spacing: -2px;
        font-family: 'CircularStd-Black';
      }
      .home--form-subtitle {
        font-size: 16px;
        letter-spacing: -0.8px;
      }
    }
    .home--form-body {
      padding-top: 110px;
      .home--form-body-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        .home--form-header {
          display: flex;
          align-items: center;
          text-align: left;
          font-size: 23px;
          padding-bottom: 22px;
        }
      
        .contact-form-block {
          padding-bottom: 25px;
          .contact-input {
            width: 100%;
          }
          .custom-text-area {
            width: 100%;
          }
        }
        .contact-form-extra-wrapper {
          background-color: rgba(255, 255, 255, 33%);
          padding: 25px 30px 40px 30px;
          font-size: 12px;
          border-radius: 16px;
        }
        .contact-form-more {
          max-width: 770px;
          margin: 0 auto;
        
          .contact-agreement {
            .checkbox__label {
              font-size: 14px;
              letter-spacing: -0.8px;
              font-family: 'CircularStd-Book';
            }
          }
          .contact-extra {
            font-size: 12px;
            letter-spacing: -0.6px;
            font-family: 'CircularStd-Book';
            padding-top: 23px;
          }
          button {
            font-size: 16px;
          }
          .contact-form-requirements {
            padding-bottom: 3px;
            a {
              text-decoration: none;
              color: black
            }
          }
        }
        .contact-send {
          padding-top: 30px;
          display: flex;
          justify-content: end;
        }
        .contact-error {
          padding-top: 1rem;
          .contact-error-wrapper {
            background-color: rgba(251, 206, 199);
            color: rgba(210, 35, 8);
            padding: 1rem;
            border-radius: 16px;
            text-align: center;
          }
        }
        .contact-ok {
          padding-top: 1rem;
          .contact-error-wrapper {
            background-color: rgba(224, 245, 217);
            color: rgb(98, 136, 87);
            padding: 1rem;
            border-radius: 16px;
            text-align: center;
          }
        }
      }
    }
  }
}

.contact-body-wrapper {
  padding-top: 162px;
  padding: 162px 12px;
  .contact-title {
    font-size: 60px;
    letter-spacing: -3px;
    font-family: 'CircularStd-Black';
    padding-bottom: 30px;
  }
  .contact-body-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .contact-body-container-wrapper {
      width: 815px;
      text-align: left;
      .contact-subtitle {
        font-size: 30px;
        font-family: 'CircularStd-Book';
        padding-bottom: 43px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .home--form {
    height: auto;
    padding-bottom: 40px;
    background-image: url('/assets/home/formulary/form_factura.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .home--form-wrapper {
      padding-top: 80px;
      .home--form-titles {
        text-align: center;
        .home--form-title {
          font-size: 35px;
          letter-spacing: -2px;
          font-family: 'CircularStd-Black';
        }
        .home--form-subtitle {
          font-size: 16px;
          letter-spacing: -0.8px;
        }
      }
      .home--form-body {
        padding: 80px 40px;
        .home--form-body-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          .home--form-header {
            display: flex;
            align-items: center;
            text-align: left;
            font-size: 20px;
            padding-bottom: 22px;
            padding-left: 50px;
          }
          .contact-form-block {
            padding-bottom: 10px;
            .contact-input {
              width: 100%;
            }
            .custom-text-area {
              width: 100%;
            }
          }
          .contact-form-more,
          .contact-send {
            max-width: 100%;
            margin: 0 auto;
            .contact-agreement {
              .checkbox__label {
                font-size: 16px;
                letter-spacing: -0.8px;
                font-family: 'CircularStd-Book';
              }
            }
            .contact-extra {
              font-size: 12px;
              letter-spacing: -0.6px;
              font-family: 'CircularStd-Book';
              padding-top: 23px;
            }
            button {
              font-size: 16px;
            }
          }
          .contact-send {
            padding-top: 10px;
            display: flex;
            justify-content: end;
          }
        }
      }
    }
  }
  .contact-body-wrapper {
    padding-top: 182px;
    padding: 162px 12px;
    .contact-title {
      font-size: 60px;
      letter-spacing: -3px;
      font-family: 'CircularStd-Black';
      padding-bottom: 30px;
    }
    .contact-body-container {
      width: 100%;
      display: flex;
      justify-content: center;
      .contact-body-container-wrapper {
        width: 100%;
        text-align: left;
        .contact-subtitle {
          font-size: 30px;
          font-family: 'CircularStd-Book';
          padding-bottom: 43px;
        }
      }
    }
  }
}
</style>
