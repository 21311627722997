<script setup>
import AOS from 'aos'
import 'aos/dist/aos.css'

AOS.init({
  duration: 2000,
  once: true
})
</script>
<template>
  <section class="home--advantages">
    <div class="home--advantages-wrapper container">
      <div class="home--advantages-titles">
        <div class="home--advantages-title">
          {{ $t('home_advantages.title') }}
        </div>
        <div class="home--advantages-subtitle">
          {{ $t('home_advantages.subtitle') }}
        </div>
      </div>
      <div class="home--advantages-body" data-aos="fade">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="home--advantages-body-block">
              <span class="primary-color">
                {{ $t('home_advantages.text_1_branding') }}
              </span>
              {{ $t('home_advantages.text_1') }}
            </div>
            <div class="home--advantages-body-block">
              <span class="primary-color">
                {{ $t('home_advantages.text_2_branding') }}
              </span>
              {{ $t('home_advantages.text_2') }}
            </div>
            <div class="home--advantages-body-block">
              <span class="primary-color">
                {{ $t('home_advantages.text_3_branding') }}
              </span>
              {{ $t('home_advantages.text_3') }}
            </div>
            <div class="home--advantages-body-block">
              <span class="primary-color">
                {{ $t('home_advantages.text_4_branding') }}
              </span>
              {{ $t('home_advantages.text_4') }}
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="home--advantages-body-block">
              <span class="primary-color">
                {{ $t('home_advantages.text_5_branding') }}
              </span>
              {{ $t('home_advantages.text_5') }}
            </div>
            <div class="home--advantages-body-block">
              <span class="primary-color">
                {{ $t('home_advantages.text_6_branding') }}
              </span>
              {{ $t('home_advantages.text_6') }}
            </div>
            <div class="home--advantages-body-block">
              <span class="primary-color">
                {{ $t('home_advantages.text_7_branding') }}
              </span>
              {{ $t('home_advantages.text_7') }}
            </div>
            <div class="home--advantages-body-block">
              <span class="primary-color">
                {{ $t('home_advantages.text_8_branding') }}
              </span>
              {{ $t('home_advantages.text_8') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.home--advantages {
  background-color: black;
  .home--advantages-wrapper {
    padding-top: 115px;
    padding-bottom: 125px;
    padding: 115px 50px 125px 50px;
    .home--advantages-titles {
      color: white;
      text-align: center;
      .home--advantages-title {
        font-size: 40px;
        letter-spacing: -2px;
        font-family: 'CircularStd-Black';
        padding-bottom: 15px;
      }
      .home--advantages-subtitle {
        font-size: 16px;
        letter-spacing: -0.8px;
      }
    }
    .home--advantages-body {
      padding-top: 65px;
      color: white;
      .home--advantages-body-block {
        padding-bottom: 18px;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .home--advantages {
    background-color: black;
    .home--advantages-wrapper {
      padding: 50px 20px;
      .home--advantages-titles {
        color: white;
        text-align: center;
        .home--advantages-title {
          font-size: 35px;
          letter-spacing: -2px;
          font-family: 'CircularStd-Black';
          padding-bottom: 15px;
        }
        .home--advantages-subtitle {
          font-size: 15px;
          letter-spacing: -0.8px;
        }
      }
      .home--advantages-body {
        padding-top: 45px;
        color: white;
        .home--advantages-body-block {
          padding-bottom: 18px;
        }
      }
    }
  }
}
</style>
