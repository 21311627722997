<script setup>
import { RouterView } from 'vue-router'

const emit = defineEmits(['onChangeActive'])
defineProps({
  currentComponent: String
})

function onChangeActive(name) {
  emit('onChangeActive', name)
}
</script>
<template>
  <div class="app_content" :class="currentComponent">
    <RouterView
      :key="$route.fullPath"
      :activeComponent="currentComponent"
      @onChangeActive="onChangeActive"
    />
  </div>
</template>

<style lang="scss" scoped>
.app_content {
  width: 100%;
}
</style>
