import { createI18n } from 'vue-i18n'
import es from '../locales/es.json'

let locale = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1')
const defaultLocale = 'es'
const availableLocales = ['es']

locale = locale.replace('car', '')
locale = locale.trim().length && locale != '/' ? locale : defaultLocale

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale,
  fallbackLocale: 'es',
  availableLocales,
  messages: { es },
  fallbackWarn: false,
  missingWarn: false
})
