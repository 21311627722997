<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useRouter, useRoute, NavigationFailureType, isNavigationFailure } from 'vue-router'

const menuOpen = ref(false)
const menuChecked = ref(false)
const navbar = ref()
const route = useRoute()
const router = useRouter()

const emit = defineEmits(['onChangeActive'])
defineProps({
  currentComponent: String
})

const homeIntro = ref()
const homeDigitalize = ref()
const homeJourney = ref()
const homeInvoice = ref()
const homeTry = ref()
const homeBlocks = ref()
const homeAdvantages = ref()
const homeConnect = ref()
const homeContact = ref()

let blogUrl = 'https://blog.cloudactivereception.com/es'

onMounted(() => {
  window.addEventListener('click', closeNavbar)
})

onBeforeUnmount(() => {
  window.removeEventListener('click', closeNavbar)
})

function closeMenu() {
  menuChecked.value = false
  menuOpen.value = false
}

const showScrollInto = async (name) => {
  if(route.path == '/' || route.path == '/carfactura') {
    emit('onChangeActive', name)
  } else {
    const failure = await router.push({ name: 'home', hash: '#' + name })
    if (!isNavigationFailure(failure, NavigationFailureType.aborted)) {
      emit('onChangeActive', name, name)
    }
  }
}

const resetComponent = () => {
  emit('onChangeActive', 'homeIntro')
}

const closeNavbar = (event) => {
  if (event.targer != navbar.value && !event.composedPath().includes(navbar.value)) {
    closeMenu()
  }
}
</script>
<template>
  <nav class="navbar navbar-expand-lg fixed-top" ref="navbar">
    <div class="w-100 h-100">
      <div class="navbar-wrapper">
        <div class="container h-100">
          <div class="container-fluid">
            <router-link to="/" class="navbar-brand d-flex" @click="resetComponent()">
              <img src="/assets/logo-white.png" />
              <div class="navbar-title">factura</div>
            </router-link>
            <div class="navbar-collapse" id="navbarSupportedContent">
              <div class="navbar-button-content">
                <div class="navbar-button" @click="menuOpen = !menuOpen">
                  <div class="navbar-button">
                    <input type="checkbox" id="menu_checkbox" v-model="menuChecked" />
                    <label for="menu_checkbox" @click="menuOpen = !menuOpen">
                      <div></div>
                      <div></div>
                    </label>
                  </div>
                </div>
              </div>
              <div class="navbar-menu">
                <div class="navbar-menu-items">
                  <div
                    class="navbar-menu-item"
                    :class="[currentComponent == 'homeIntro' ? 'active' : '']"
                    @click="showScrollInto('homeIntro')"
                  >
                    {{ $t('navbar.what_is') }}
                  </div>
                  <div
                    class="navbar-menu-item"
                    :class="[currentComponent == 'homeJourney' ? 'active' : '']"
                    @click="showScrollInto('homeJourney')"
                  >
                    {{ $t('navbar.workshop') }}
                  </div>
                  <div
                    class="navbar-menu-item"
                    :class="[currentComponent == 'homeInvoice' ? 'active' : '']"
                    @click="showScrollInto('homeInvoice')"
                  >
                    {{ $t('navbar.garanty') }}
                  </div>
                  <div
                    class="navbar-menu-item"
                    :class="[currentComponent == 'homeBlocks' ? 'active' : '']"
                    @click="showScrollInto('homeBlocks')"
                  >
                    {{ $t('navbar.functionalities') }}
                  </div>
                  <div
                    class="navbar-menu-item"
                    :class="[currentComponent == 'homeAdvantages' ? 'active' : '']"
                    @click="showScrollInto('homeAdvantages')"
                  >
                    {{ $t('navbar.advantages') }}
                  </div>
                  <div
                    class="navbar-menu-item"
                    :class="[currentComponent == 'homeConnect' ? 'active' : '']"
                    @click="showScrollInto('homeConnect')"
                  >
                    {{ $t('navbar.connect') }}
                  </div>
                  <div
                    class="navbar-menu-item"
                    :class="[currentComponent == 'homeContact' ? 'active' : '']"
                    @click="showScrollInto('homeContact')"
                  >
                    {{ $t('navbar.contact') }}
                  </div>
                </div>
              </div>
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    target="_blank"
                    aria-current="page"
                    href="https://www.linkedin.com/company/cloud-active-reception/"
                  >
                    <font-awesome-icon icon="fa-brands fa-linkedin-in" />
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    target="_blank"
                    href="https://www.youtube.com/@carcloudactivereception8918"
                  >
                    <font-awesome-icon icon="fa-brands fa-youtube" />
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    target="_blank"
                    href="https://www.instagram.com/cloudactivereception/"
                  >
                    <font-awesome-icon icon="fa-brands fa-instagram" />
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link link-blog" target="_blank" :href="blogUrl">
                      <font-awesome-icon icon="fa-solid fa-blog" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <transition name="slide-fade">
        <div class="expanded-menu" v-show="menuOpen">
          <div class="container expanded-wrapper">
            <div class="expanded-inner-wrapper">
              <div class="navbar-menu-items">
                <div
                  class="navbar-menu-item"
                  :class="[currentComponent == 'homeIntro' ? 'active' : '']"
                  @click="showScrollInto('homeIntro')"
                >
                  <div class="navbar-menu-item-text">
                    {{ $t('navbar.what_is') }}
                  </div>
                </div>
                <div
                  class="navbar-menu-item"
                  :class="[currentComponent == 'homeJourney' ? 'active' : '']"
                  @click="showScrollInto('homeJourney')"
                >
                  <div class="navbar-menu-item-text">
                    {{ $t('navbar.workshop') }}
                  </div>
                </div>
                <div
                  class="navbar-menu-item"
                  :class="[currentComponent == 'homeInvoice' ? 'active' : '']"
                  @click="showScrollInto('homeInvoice')"
                >
                  <div class="navbar-menu-item-text">
                    {{ $t('navbar.garanty') }}
                  </div>
                </div>
                <div
                  class="navbar-menu-item"
                  :class="[currentComponent == 'homeBlocks' ? 'active' : '']"
                  @click="showScrollInto('homeBlocks')"
                >
                  <div class="navbar-menu-item-text">
                    {{ $t('navbar.functionalities') }}
                  </div>
                </div>
                <div
                  class="navbar-menu-item"
                  :class="[currentComponent == 'homeAdvantages' ? 'active' : '']"
                  @click="showScrollInto('homeAdvantages')"
                >
                  <div class="navbar-menu-item-text">
                    {{ $t('navbar.advantages') }}
                  </div>
                </div>
                <div
                  class="navbar-menu-item"
                  :class="[currentComponent == 'homeConnect' ? 'active' : '']"
                  @click="showScrollInto('homeConnect')"
                >
                  <div class="navbar-menu-item-text">
                    {{ $t('navbar.connect') }}
                  </div>
                </div>
                <div
                  class="navbar-menu-item"
                  :class="[currentComponent == 'homeContact' ? 'active' : '']"
                  @click="showScrollInto('homeContact')"
                >
                  <div class="navbar-menu-item-text">
                    {{ $t('navbar.contact') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.navbar {
  padding: 0;
  color: white;
  height: 70px;
  img {
    height: 33px;
  }
  a {
    color: white;
    div {
      padding-left: 7px;
      padding-top: 7px;
    }
  }
  :focus,
  :hover {
    color: white !important;
  }
  .navbar-nav {
    display: flex;
    justify-items: flex-end;
  }
  .navbar-collapse {
    justify-content: flex-end;
  }
  .navbar-title {
    font-size: 21px;
    font-family: 'CircularStd-Black';
  }
  .navbar-button-content {
    display: none;
  }
  .navbar-menu {
    .navbar-menu-items {
      display: flex;
      font-size: 14px;
      .navbar-menu-item {
        padding: 0 10px;
        color: rgba(255, 255, 255, 0.6);
        cursor: pointer;
        &:last-child {
          padding-right: 30px;
        }
        &.active {
          color: white;
        }
      }
    }
  }
}
.navbar-button-content {
  padding-right: 20px;
}
.navbar-button {
  position: relative;
  border: none;
  width: 50px;
}
.navbar-button:focus {
  border: none;
}
.navbar-wrapper {
  height: 100%;
  background-color: black;
  .container {
    padding: 0.5rem 0rem;
  }
}
.nav-link {
  &:hover {
    animation: pulse 1s 1;
    animation-timing-function: linear;
  }
  &.link-blog {
    font-size: 15px;
  }
}

#menu_checkbox {
  display: none;
}

label {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: block;
  width: 30px;
  height: 31px;
  transform: translateY(-50%);
  transition: 0.3s ease transform;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

label div {
  width: 6px;
  height: 3px;
  background-color: white;
  margin-left: 0;
  margin-bottom: 4px;
  border-radius: 0px;
  transition:
    0.7s ease width,
    0.7s ease margin-left,
    0.7s ease margin-bottom,
    0.7s ease background-color;
}

label div:first-child {
  width: 30px;
}

label div:nth-child(2) {
  width: 15px;
}

#menu_checkbox:checked + label {
  transform: translateY(-50%) rotateZ(-90deg);
}

#menu_checkbox:checked + label div {
  width: 10px;
  margin-bottom: 3px;
  background-color: white;
  &:first-child {
    width: 24px;
    transform: rotate(45deg);
    margin-bottom: -1px;
  }
  &:last-child {
    width: 24px;
    transform: rotate(135deg);
    margin-bottom: -1px;
  }
}

.navbar .container,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl,
.navbar .container-xxl {
  display: block;
}
.navbar .container-fluid {
  height: 100%;
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.expanded-menu {
  padding: 50px 75px;
  background-color: rgba(0, 0, 0, 0.77);
  backdrop-filter: blur(7px);
  .expanded-wrapper {
    display: flex;
    padding-left: 40px;
    padding-right: 40px;
    .expanded-inner-wrapper {
      display: flex;
      gap: 125px;
    }
    .expanded-title {
      font-size: 32px;
      letter-spacing: -1.6px;
      font-family: 'CircularStd-Black';
      padding-bottom: 25px;
    }
    .expanded-content {
      font-size: 18px;
      letter-spacing: -0.9px;
      display: flex;
      flex-direction: column;
      align-items: start;
      .expandend-content-block {
        cursor: pointer;
        position: relative;
        line-height: 16px;
        a {
          text-decoration: none;
          position: relative;
          display: block;
          transition: 0.5s;
          transition: white 100ms linear;
          padding-bottom: 9px;
          &:before {
            position: absolute;
            display: block;
            left: 0.05rem;
            height: 1px;
            bottom: 0.5rem;
            content: '';
            background: white;
            -webkit-transition: transform 300ms ease;
            -moz-transition: transform 300ms ease;
            transition: transform 300ms ease;
            -webkit-transform-origin: 50% 50%;
            -moz-transform-origin: 50% 50%;
            -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
            -webkit-transform: scaleX(0);
            -moz-transform: scaleX(0);
            -ms-transform: scaleX(0);
            transform: scaleX(0);
            width: 20px;
          }

          &:hover::before {
            transform: scaleX(1);
            transform-origin: left;
          }
        }
      }
    }
  }
}
.menu-collapse {
  font-size: 23px;
  cursor: pointer;
}

.slide-fade-enter-active {
  transition: all 1s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.1s ease-out;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 1000px) {
  .navbar {
    .navbar-menu {
      display: none;
    }
    .navbar-button-content {
      display: block;
    }
  }
  .navbar .navbar-nav {
    display: none;
  }
  #navbarSupportedContent {
    display: flex;
  }

  .expanded-menu {
    padding: 10px 15px;
    height: 450px;
    .expanded-wrapper {
      padding: 0 20px;
      .expanded-inner-wrapper {
        display: block;
        padding: 20px 10px;
        .navbar-menu-item {
          padding-bottom: 30px;
          text-decoration: none;
          position: relative;
          display: block;
          transition: 0.5s;
          transition: white 100ms linear;
          cursor: pointer;
          .expanded-title {
            font-size: 1.5rem;
          }
          .navbar-menu-item-text {
            &:before {
              position: absolute;
              display: block;
              left: 0.05rem;
              height: 1px;
              top: 30px;
              content: '';
              background: white;
              -webkit-transition: transform 300ms ease;
              -moz-transition: transform 300ms ease;
              transition: transform 300ms ease;
              -webkit-transform-origin: 50% 50%;
              -moz-transform-origin: 50% 50%;
              -ms-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
              -webkit-transform: scaleX(0);
              -moz-transform: scaleX(0);
              -ms-transform: scaleX(0);
              transform: scaleX(0);
              width: 20px;
            }

            &:hover::before {
              transform: scaleX(1);
              transform-origin: left;
            }
          }
        }
      }
      .expanded-title {
        font-size: 1.5rem;
      }
    }
  }
}
@media (max-width: 390px) {
  .expanded-menu {
    height: calc(100vh - 140px);
  }
  .expanded-menu .expanded-wrapper .expanded-title {
    padding-bottom: 5px;
  }
  #menu_checkbox:checked + label div:first-child {
    margin-bottom: -2px;
  }
}
</style>
