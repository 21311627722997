<script setup>
    import { ref } from 'vue';

    const showModal = ref(false);

    function openModal() {
        showModal.value = true;
    }
    function closeModal() {
        showModal.value = false;
    }
    const videoUrl = ref("https://www.youtube.com/embed/wPSHMJilY_s?si=PjC4c8U7veP2-u6L?autoplay=1");


</script>
<template>
  <section class="home--intro">
    <div class="home--intro-wrapper container">
      <div class="home--intro-row">
        <div class="col-lg-4 col-12 col-row">
          <div class="home--intro-block-title">
            {{ $t('home_intro.block_1_title') }}
          </div>
          <div class="home--intro-block-content">
            {{ $t('home_intro.block_1_text_1') }}
          </div>
          <div class="home--intro-block-content">
            <span class="home--intro-block-content-bold">
              {{ $t('home_intro.block_1_text_2_bold') }}
            </span>
            {{ $t('home_intro.block_1_text_2') }}
          </div>
          <div class="home--intro-block-content">
            <span class="home--intro-block-content-bold">
              {{ $t('home_intro.block_1_text_3_bold') }}
            </span>
            {{ $t('home_intro.block_1_text_3') }}
          </div>
          <div class="home--intro-block-content">
            <span class="home--intro-block-content-bold">
              {{ $t('home_intro.block_1_text_4_bold') }}
            </span>
            {{ $t('home_intro.block_1_text_4') }}
          </div>
          <div class="home--intro-block-content">
            <span class="home--intro-block-content-bold">
              {{ $t('home_intro.block_1_text_5_bold') }}
            </span>
            {{ $t('home_intro.block_1_text_5') }}
          </div>
        </div>
        <div class="col-lg-4 col-12 col-row">
          <div class="col-row-intro-inner-wrapper">
            <div class="home--intro-block-title">
              {{ $t('home_intro.block_2_title') }}
            </div>
            <div class="home--intro-block-content">
              {{ $t('home_intro.block_2_text_1') }}
            </div>
            <div class="home--intro-block-content">
              {{ $t('home_intro.block_2_text_2') }}
              <span class="home--intro-block-content-bold">
                {{ $t('home_intro.block_2_text_2_bold') }}
              </span>
            </div>
            <div class="home--intro-block-content">
              <span class="home--intro-block-content-bold">
                {{ $t('home_intro.block_2_text_3_bold') }}
              </span>
              {{ $t('home_intro.block_2_text_3_1') }}
            </div>
            <div class="home--intro-block-content">
              {{ $t('home_intro.block_2_text_4') }}
              <span class="home--intro-block-content-bold">
                {{ $t('home_intro.block_2_text_4_bold') }}
              </span>
            </div>
            <div class="home--intro-block-content">
              {{ $t('home_intro.block_2_text_5') }}
              <span class="home--intro-block-content-bold">
                {{ $t('home_intro.block_2_text_5_bold') }}
              </span>
              {{ $t('home_intro.block_2_text_5_1') }}
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-12">
          <div class="home--intro-block-title">
            {{ $t('home_intro.block_3_title') }}
          </div>
          <div class="home--intro-block-content content-fake">
            {{ $t('home_intro.block_3_text_1') }}
          </div>
          <div class="home--intro-block-content">
            <span class="home--intro-block-content-bold">
              {{ $t('home_intro.block_3_text_2') }}
            </span>
          </div>
          <div class="home--intro-block-title">
            {{ $t('home_intro.block_3_title_2') }}
          </div>
          <div class="home--intro-block-content">
            {{ $t('home_intro.block_3_text_2_1') }}
            <span class="home--intro-block-content-bold">
              <a href="https://cloudactivereception.com" target="_blank">
                {{ $t('home_intro.block_3_text_2_2') }}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="home--car-video-container">
    <div class="info-container">
      <div class="block-left">
            <h1 class="home-block-4-title">{{ $t('home_intro.block_4_title') }}</h1>
            <p class="block-4-text">{{ $t('home_intro.block_4_text_1') }}</p>
            <p class="block-4-text">{{ $t('home_intro.block_4_text_2') }}</p>
      </div>
      <div class="block-right">
        <div class="video-container">
          <iframe
            :src="videoUrl"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.home--intro {
  background-color: var(--car-factura-primary);
  background-color: rgb(111, 128, 154, 0.63);
  padding-top: 90px;
  padding-bottom: 70px;
  .home--intro-wrapper {
    .home--intro-block-title {
      font-size: 26px;
      letter-spacing: -1.3px;
      color: white;
      font-family: 'CircularStd-Black';
      padding-bottom: 20px;
    }
    .home--intro-block-content {
      font-size: 16px;
      letter-spacing: -0.8px;
      padding-bottom: 20px;
      .home--intro-block-content-bold {
        font-family: 'CircularStd-Bold';
        a {
          text-decoration: none;
          color: black;
          display: block;
          transition: 0.5s;
          transition: white 100ms linear;
          position: relative;
          width: fit-content;
          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            border-radius: 4px;
            background-color: black;
            bottom: -2px;
            left: 0;
            transform-origin: right;
            transform: scaleX(0);
            transition: transform 0.3s ease-in-out;
          }
          &:hover:before {
            transform-origin: left;
            transform: scaleX(1);
          }
        }
      }
      &.content-fake {
        padding-bottom: 5px;
      }
    }
    .home--intro-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 67px;
      max-width: 1065px;
      margin: 0 auto;
    }
  }
}

.home--car-video-container{
    background-color: #6B7482;
    .info-container{
      padding: 10dvh 0;
      display: flex;
      justify-content: center;
      gap: 150px;
      max-width: 1200px;
      margin: auto;
      .block-left{
        h1{
          font-family: 'CircularStd-Black';
          font-size: 26px;
          color: #FFFFFF;
        }
        p{
          font-family: 'CircularStd-Book';
          font-size: 16px;
          color: #000000;
          margin-top: 30px;
        }
      }
      iframe{
        width: 520px;
        height: 295px;
        border-radius: 20px;
      } 
    }
  }

@media screen and (max-width: 1000px) {
  .home--intro {
    background-color: var(--car-factura-primary);
    background-color: rgb(111, 128, 154, 0.63);
    padding-top: 90px;
    padding-bottom: 53px;
    .home--intro-wrapper {
      .home--intro-block-title {
        font-size: 18px;
        letter-spacing: -1.3px;
        color: white;
        font-family: 'CircularStd-Black';
        padding-bottom: 10px;
      }
      .home--intro-block-content {
        font-size: 15px;
        letter-spacing: -0.8px;
        padding-bottom: 15px;
        .home--intro-block-content-bold {
          font-family: 'CircularStd-Bold';
        }
        &.content-fake {
          padding-bottom: 5px;
        }
      }
      .home--intro-row {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        .col-row {
          padding-bottom: 30px;
        }
      }
    }
  }
  .home--car-video-container{
    background-color: #6B7482;
    .info-container{
      padding: 10dvh;
      flex-wrap: wrap;
      gap: 60px;
      iframe{
        width: auto;
        height: auto;
        border-radius: 20px;
      } 
    }
  }
}
</style>

