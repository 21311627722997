<script setup>
import { ref } from 'vue'

import AppCarousel from '@/components/home/app/AppCarousel.vue'
import { useHomeSlideStore } from '@/stores/homeSlide.js'
import 'vue3-carousel/dist/carousel.css'
import '@/assets/custom_carousel.css'
import { Carousel, Slide, Pagination } from 'vue3-carousel'
import CustomCarouselItem from '@/components/commons/customCarousel/CustomCarouselItem.vue'
import App1 from '@/components/home/app/App1.vue'
import App2 from '@/components/home/app/App2.vue'
import App3 from '@/components/home/app/App3.vue'
import App4 from '@/components/home/app/App4.vue'
import App5 from '@/components/home/app/App5.vue'
import App6 from '@/components/home/app/App6.vue'

const storeSlide = useHomeSlideStore()
const isHover = ref(false)

function changeActive(value) {
  storeSlide.setActive(value)
}

function stopCarousel(index) {
  isHover.value = true
  storeSlide.setActive(index)
}

function restartCarousel() {
  isHover.value = false
}
</script>
<template>
  <section class="home--conntect">
    <div class="container" data-aos="fade-up">
      <div class="wrapper header-wrapper">
        <div class="title">
          {{ $t('home_connect.title') }}
          <span class="primary-color">
            {{ $t('home_connect.title_branding') }}
          </span>
        </div>
        <div class="subtitle">
          {{ $t('home_connect.subtitle') }}
        </div>
      </div>
      <div class="wrapper wrapper-in">
        <div class="app-show">
          <div class="app-left">
            <div
              class="block b-1"
              :class="[storeSlide.active == 1 ? 'active' : '']"
              @click="changeActive(1)"
              @mouseover="stopCarousel(1)"
              @mouseleave="restartCarousel"
            >
              <div class="text-block">
                <div class="title">
                  {{ $t('home_connect.appointment_requests') }}
                </div>
                <div class="subtitle">
                  {{ $t('home_connect.appointment_requests_subtitle') }}
                </div>
              </div>
              <div class="icon-block">
                <font-awesome-icon icon="fa-solid fa-circle-plus" />
              </div>
            </div>
            <div
              class="block extra-big b-2"
              :class="[storeSlide.active == 2 ? 'active' : '']"
              @click="changeActive(2)"
              @mouseover="stopCarousel(2)"
              @mouseleave="restartCarousel"
            >
              <div class="text-block">
                <div class="title">
                  {{ $t('home_connect.sms') }}
                  <br />
                  {{ $t('home_connect.sms_2') }}
                </div>
                <div class="subtitle">
                  {{ $t('home_connect.sms_subtitle') }}
                </div>
              </div>
              <div class="icon-block">
                <font-awesome-icon icon="fa-solid fa-circle-plus" />
              </div>
            </div>
            <div
              class="block b-3"
              :class="[storeSlide.active == 3 ? 'active' : '']"
              @click="changeActive(3)"
              @mouseover="stopCarousel(3)"
              @mouseleave="restartCarousel"
            >
              <div class="text-block">
                <div class="title">
                  {{ $t('home_connect.surveys') }}
                </div>
                <div class="subtitle">
                  {{ $t('home_connect.surveys_subtitle') }}
                </div>
              </div>
              <div class="icon-block">
                <font-awesome-icon icon="fa-solid fa-circle-plus" />
              </div>
            </div>
          </div>
          <div class="app-center">
            <div class="wrapper-image">
              <AppCarousel :isHover="isHover" />
            </div>
          </div>
          <div class="app-right">
            <div
              class="block b-4"
              :class="[storeSlide.active == 4 ? 'active' : '']"
              @click="changeActive(4)"
              @mouseover="stopCarousel(4)"
              @mouseleave="restartCarousel"
            >
              <div class="icon-block">
                <font-awesome-icon icon="fa-solid fa-circle-plus" />
              </div>
              <div class="text-block">
                <div class="title">
                  {{ $t('home_connect.contactless') }}
                </div>
                <div class="subtitle">
                  {{ $t('home_connect.contactless_subtitle') }}
                </div>
              </div>
            </div>
            <div
              class="block b-5"
              :class="[storeSlide.active == 5 ? 'active' : '']"
              @click="changeActive(5)"
              @mouseover="stopCarousel(5)"
              @mouseleave="restartCarousel"
            >
              <div class="icon-block">
                <font-awesome-icon icon="fa-solid fa-circle-plus" />
              </div>
              <div class="text-block">
                <div class="title">
                  {{ $t('home_connect.validation') }}
                </div>
                <div class="subtitle">
                  {{ $t('home_connect.validations_subtitle') }}
                </div>
              </div>
            </div>
            <div
              class="block b-6"
              :class="[storeSlide.active == 6 ? 'active' : '']"
              @click="changeActive(6)"
              @mouseover="stopCarousel(6)"
              @mouseleave="restartCarousel"
            >
              <div class="icon-block">
                <font-awesome-icon icon="fa-solid fa-circle-plus" />
              </div>
              <div class="text-block">
                <div class="title">
                  {{ $t('home_connect.quotation') }}
                </div>
                <div class="subtitle">
                  {{ $t('home_connect.quotation_subtitle') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="app-show-movile">
          <div class="custom-carousel-home-app">
            <div class="custom-carousel-inner-wrapper custom-carousel-home-app-wrapper">
              <carousel :items-to-show="1" :wrap-around="true" :transition="1000">
                <slide :key="1">
                  <CustomCarouselItem>
                    <App1 />
                    <div class="block b-1">
                      <div class="text-block">
                        <div class="title-carousel">
                          {{ $t('home_connect.appointment_requests') }}
                        </div>
                        <div class="subtitle-carousel">
                          {{ $t('home_connect.appointment_requests_subtitle') }}
                        </div>
                      </div>
                    </div>
                  </CustomCarouselItem>
                </slide>
                <slide :key="2">
                  <CustomCarouselItem>
                    <App2 />
                    <div class="block b-1">
                      <div class="text-block">
                        <div class="title-carousel">
                          {{ $t('home_connect.sms') }}
                        </div>
                        <div class="subtitle-carousel">
                          {{ $t('home_connect.sms_2') }}
                        </div>
                      </div>
                    </div>
                  </CustomCarouselItem>
                </slide>
                <slide :key="3">
                  <CustomCarouselItem>
                    <App3 />
                    <div class="block b-1">
                      <div class="text-block">
                        <div class="title-carousel">
                          {{ $t('home_connect.surveys') }}
                        </div>
                        <div class="subtitle-carousel">
                          {{ $t('home_connect.surveys_subtitle') }}
                        </div>
                      </div>
                    </div>
                  </CustomCarouselItem>
                </slide>
                <slide :key="4">
                  <CustomCarouselItem>
                    <App4 />
                    <div class="block b-1">
                      <div class="text-block">
                        <div class="title-carousel">
                          {{ $t('home_connect.contactless') }}
                        </div>
                        <div class="subtitle-carousel">
                          {{ $t('home_connect.contactless_subtitle') }}
                        </div>
                      </div>
                    </div>
                  </CustomCarouselItem>
                </slide>
                <slide :key="5">
                  <CustomCarouselItem>
                    <App5 />
                    <div class="block b-1">
                      <div class="text-block">
                        <div class="title-carousel">
                          {{ $t('home_connect.validation') }}
                        </div>
                        <div class="subtitle-carousel">
                          {{ $t('home_connect.validations_subtitle') }}
                        </div>
                      </div>
                    </div>
                  </CustomCarouselItem>
                </slide>
                <slide :key="6">
                  <CustomCarouselItem>
                    <App6 />
                    <div class="block b-1">
                      <div class="text-block">
                        <div class="title-carousel">
                          {{ $t('home_connect.quotation') }}
                        </div>
                        <div class="subtitle-carousel">
                          {{ $t('home_connect.quotation_subtitle') }}
                        </div>
                      </div>
                    </div>
                  </CustomCarouselItem>
                </slide>
                <template #addons>
                  <pagination />
                </template>
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.home--conntect {
  padding-bottom: 150px;
  background: transparent linear-gradient(180deg, #f1f1f1 0%, #ffffff 31%, #f3f3f3 100%) 0% 0%
    no-repeat padding-box;
  .wrapper {
    padding-top: 100px;
    &.wrapper-in {
      padding-top: 10px;
    }
    .title {
      font-size: 40px;
      font-family: 'CircularStd-Black';
      letter-spacing: -2px;
      text-align: center;
    }
    .subtitle {
      font-size: 16px;
      text-align: center;
      padding-top: 6px;
    }
    .app-show {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 40px;
      .app-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        min-height: 460px;
        padding-right: 30px;
        padding-top: 30px;
        .block {
          text-align: right;
          display: flex;
          align-self: flex-end;
          flex: 1;
          max-width: 230px;
          color: #b8b8b8;
          cursor: pointer;
          &.extra-big {
            max-width: 260px;
          }
          .title {
            font-size: 20px;
            text-align: right;
            line-height: 20px;
            padding-bottom: 5px;
            letter-spacing: -1px;
          }
          .subtitle {
            font-size: 16px;
            text-align: right;
            letter-spacing: -1px;
          }
          .icon-block {
            padding-left: 10px;
            font-size: 20px;
          }
          &.active {
            color: black;
          }
        }
        .block:nth-child(even) {
          padding-right: 30px;
        }
      }
      .app-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        min-height: 460px;
        padding-left: 30px;
        padding-top: 30px;
        .block {
          align-self: flex-start;
          display: flex;
          flex: 1;
          max-width: 230px;
          color: #b8b8b8;
          cursor: pointer;

          .title {
            font-size: 20px;
            text-align: left;
            line-height: 20px;
            padding-bottom: 5px;
            letter-spacing: -1px;
          }
          .subtitle {
            font-size: 16px;
            text-align: left;
            letter-spacing: -1px;
          }
          &.active {
            color: black;
          }
        }
        .block:nth-child(even) {
          padding-left: 26px;
        }
        .icon-block {
          padding-right: 6px;
          font-size: 20px;
        }
      }
    }
  }
  .wrapper-image {
    width: 320px;
    height: 575px;
    img {
      width: 100%;
    }
  }
  .wrapper-more {
    width: 100%;
    font-size: 24px;
    letter-spacing: -1.2px;
    font-family: 'CircularStd-Bold';
    text-align: center;
    padding-top: 105px;
    svg {
      padding-left: 10px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.home--conntect .wrapper .app-show-movile {
  display: none;
}

.title-carousel {
  font-family: 'CircularStd-Black';
}
.subtitle-carousel {
  padding: 10px 50px;
  font-size: 11px;
}

@media (max-width: 1000px) {
  .home--conntect .wrapper {
    padding-top: 0px;
  }
  .title-carousel {
    font-size: 20px;
  }
  .subtitle-carousel {
    padding: 10px 120px;
    font-size: 17px;
  }
  .home--conntect .wrapper .app-show {
    display: none;
  }
  .home--conntect .wrapper .app-show-movile {
    display: block;
  }
  .home--conntect .wrapper .app-show-movile .title {
    font-size: 1.2rem;
  }
  .home--conntect .wrapper .app-show-movile .subtitle {
    font-size: 0.9rem;
  }
  .home--conntect {
    padding-top: 60px;
    padding-bottom: 60px;
    .wrapper .title {
      font-size: 2rem;
      padding-bottom: 50px;
    }
    .subtitle {
      display: none;
    }
  }
  .custom-carousel-home-app-wrapper .custom-carousel-item {
    max-height: 550px;
  }
  .custom-carousel-item-wrapper {
    height: 100%;
    width: 100%;
  }
  .custom-carousel-home-app-wrapper .custom-carousel-item .custom-carousel-item-wrapper {
    height: 100%;
    width: 100%;
    max-height: 450px;
    img {
      height: 100%;
      max-height: 420px;
    }
  }
}
@media (max-width: 450px) {
  .custom-carousel-home-app-wrapper .custom-carousel-item {
    max-height: 450px;
    .custom-carousel-item-wrapper {
      height: 100%;
      width: 100%;
    }
  }
  .custom-carousel-item-wrapper {
    height: 100%;
    max-height: 350px;
    object-fit: contain;
  }
  .custom-carousel-home-app-wrapper .custom-carousel-item .custom-carousel-item-wrapper {
    height: 100%;
    width: 100%;
    max-height: 450px;
    img {
      height: 100%;
      max-height: 350px;
      object-fit: contain;
    }
  }

  .title-carousel {
    font-size: 14px;
  }
  .subtitle-carousel {
    font-size: 12px;
    padding: 10px 50px;
  }
}
</style>
