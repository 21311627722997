import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import { i18n } from './utils/i18n.js'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import * as CustomFontAwesome from './utils/CustomFontAwesome.js'

import './assets/main.css'

const app = createApp(App)
app.use(router)
app.use(i18n)
app.use(createPinia())
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
