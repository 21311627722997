<script setup>
import AOS from 'aos'
import 'aos/dist/aos.css'

AOS.init({
  duration: 2000,
  once: true
})

const emit = defineEmits(['onGoToContact'])
defineProps({
  activeComponent: String
})

const goToContact = () => {
  emit('onGoToContact', 'homeContact')
}
</script>
<template>
  <section class="home--digitalize">
    <div class="home--digitalize-wrapper" data-aos="fade-up">
      <div class="home--digitalize-wrapper-inner">
        {{ $t('home_digitalize.title') }}
        <span class="home--digitalize-content-bold">
          {{ $t('home_digitalize.title_bold') }}
        </span>
        <div class="home--digitalize-btn">
          <button class="custom-btn-secondary" @click="goToContact">
            {{ $t('home_digitalize.button') }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.home--digitalize {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  .home--digitalize-wrapper {
    font-size: 38px;
    padding-top: 61px;
    padding-bottom: 40px;
    color: white;
    text-align: center;
    .home--digitalize-wrapper-inner {
      max-width: 820px;
      .home--digitalize-content-bold {
        font-family: 'CircularStd-Bold';
      }
      .home--digitalize-btn {
        padding-top: 40px;
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .home--digitalize {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    .home--digitalize-wrapper {
      font-size: 25px;
      padding: 61px 30px 60px 30px;
      color: white;
      text-align: center;
      .home--digitalize-wrapper-inner {
        max-width: 100%;
        .home--digitalize-content-bold {
          font-family: 'CircularStd-Bold';
        }
        .home--digitalize-btn {
          padding-top: 40px;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
