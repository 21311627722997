<script setup>
import { toRef } from 'vue'
import { useI18n } from 'vue-i18n';
import CustomNavbar from '@/components/CustomNavbar.vue'
import CustomFooter from '@/components/CustomFooter.vue'
import Layout from '@/components/layout/Layout.vue'
import VueCustomCookies from '@/components/customCookies/VueCustomCookies.vue'

let activeComponent = toRef('homeIntro')
const { t } = useI18n();

function onChangeActive(component, child) {
  activeComponent.value = component
}
</script>

<template>
  <CustomNavbar :currentComponent="activeComponent" @onChangeActive="onChangeActive" />
  <Layout :currentComponent="activeComponent" @onChangeActive="onChangeActive" />
  <CustomFooter :currentComponent="activeComponent" @onChangeActive="onChangeActive" />
  <VueCustomCookies
    :msg="t('custom_coookies.message')"
    :accept="t('custom_coookies.accept')"
    :decline="t('custom_coookies.decline')"
  />
</template>
